import React from "react";
import { useRoutes } from "react-router-dom";
import styled from "styled-components";

const NotFound = () => {

  
  return (
      <div>

          <Not>

              <NotRight>
                  <img src="/404.png" alt="" />
              </NotRight>

              <NotLeft>
                  <Nott>404 Error!!</Nott>
                  <Nott2>NOT FOUND</Nott2>
              </NotLeft>

          </Not>



      </div>
  )
  
};

export default NotFound;

const Not = styled.div`
width: 100%;
height: calc(100vh - 10vh);
display:flex;
justify-content:space-around;
align-items:center;
// background-color:#173b3f;
`;

const NotRight = styled.div`
width:50%;
height:100%;
display:flex;
justify-content:right;
align-items:center;

img{
    width:75%;
    height:90%;
}
`;

const NotLeft = styled.div`
width:50%;
height:85%;
display:flex;
flex-direction:column;
justify-content:center;
// align-items:center;
`;

const Nott = styled.div`
font-size:120px;
color:#173b3f;
font-weight:600;
font-family:cursive;
`;

const Nott2 = styled.div`
font-size:80px;
color:#173b3f;
font-weight:600;
font-family:cursive;
padding-left:50px;
`;

