import React from "react";
import { useRoutes } from "react-router-dom";

const Contact = () => {

  
  return <div style={{width:"100%", height:"90vh", backgroundColor:"lightblue", display:"flex", justifyContent:"center", alignItems:"center", fontSize:"60px", fontWeight:"600"}}>
            CONTACT PAGE

        </div>
  
};

export default Contact;