import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";



const Header = () => {

    return(
        <div>
            <Head>

                <Logo>LOGO</Logo>

                <Navs>
                    <NavLink to="/" style={{textDecoration:"none"}}>
                        <div className="Nav">Home</div>
                    </NavLink>

                    <NavLink to="/doc" style={{textDecoration:"none"}}>
                        <div className="Nav">Docs</div>
                    </NavLink>

                    <NavLink to="/about" style={{textDecoration:"none"}}>
                        <div className="Nav">About</div>
                    </NavLink>

                    <NavLink to="/contact" style={{textDecoration:"none"}}>
                        <div className="Nav">Contact</div>
                    </NavLink>
                </Navs>
            </Head>
        </div>
    )
}

export default Header

const Head = styled.div`
    width:100%;
    height:10vh;
    background-color:#173b3f;
    display:flex;
    justify-content:space-around;
    align-items:center;
`;

const Navs = styled.div`
    width:55%;
    height:100%;
    background-color:#173b3f;
    display:flex;
    justify-content:space-around;
    align-items:center;

    div{
        color:white;
        cursor:pointer;
        font-size:20px;
        text-decoration:none;
    }

    div:hover{
        color:orange;
    }
`;

const Logo = styled.div`
font-size:35px;
color:white;
font-family:cursive;
`;
