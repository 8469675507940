import React from "react";
import { useRoutes } from "react-router-dom";
import AllRoutes from "./Components/allRoutes";
import Header from "./Components/header";


function App() {
  return (
    <div>
      <Header />
      <AllRoutes />
    </div>
  );
}

export default App;
