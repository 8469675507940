import React from "react";
import { useRoutes } from "react-router-dom";
import About from "./about";
import Contact from "./contact";
import Doc from "./doc";
import Homepage from "./homepage";
import NotFound from "./notFound";

const AllRoutes = () => {

  const routing = useRoutes([
    {
      path: "/",
      element: <Homepage />
    },
    {
      path: "/contact",
      element: <Contact />
    },
    {
      path: "/about",
      element: <About />
    },
    {
      path: "/*",
      element: <NotFound />
    },
    
    {
      path: "/doc",
      element: <Doc />
    },
    // {
    //   path: "/docs"

    //   children: [
    //     {
    //       index: true,
    //       element: <Docs />
    //     }
    //     {
    //       path: "gettingStarted",
    //       element: <GettingStarted />
    //     }
      // ]
    // }


  ])

  return routing

};

export default AllRoutes;
